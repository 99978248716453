:root {
  --indigo-blue: #30119b;
  --white: #ffffff;
  --dark: #1b2125;
  --gunmetal: #464c5a;
  --pale-blue: #e4e5e6;
}

main {
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  height: 100%;
  position: relative;
}

.content {
  flex: 1 0 auto;
}

.App {
  height: 100%;
}

/* Hides the reCaptcha badge on the review page. */
.grecaptcha-badge {
  visibility: hidden;
}

.whiteBackground {
  background-color: #ffffff;
}
