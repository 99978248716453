.SingleDatePicker_picker {
  font-family: Muli;
}

.CalendarMonth_table {
  font-family: Muli;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #222222;
  border: 1px solid #222222;
}
