html {
  font-size: 62.5%;
  height: 98%;
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-family: 'Muli', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

#typography-root {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
